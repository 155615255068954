<template>
  <div class="card card-icon mb-4">
    <div class="row no-gutters">
      <div class="d-none d-md-flex col-auto card-icon-aside bg-black">
        <i data-feather="key"></i>
      </div>
      <div class="col">
        <div class="card-body py-4">
          <h5 v-if="isEditing" class="card-title text-danger">
            Editando rol: {{ currentRole.role.name }}
          </h5>
          <h5 v-else class="card-title">Crea un nuevo rol</h5>

          <div class="card-text">
            <FormulateForm
              ref="roleForm"
              @submit="submit"
              #default="{ hasErrors, isLoading }"
              :errors="errors"
            >
              <!-- Form Group (Rol)-->
              <FormulateInput
                name="name"
                v-model="formValues.name"
                label="Nombre del rol"
                placeholder="Administrador"
                :disabled="isLoading"
                validation="bail|required|min:6|max:100,length"
                :validation-messages="{
                  required: 'El nombre del rol es requerido',
                  min: 'La longitud mínima del nombre del rol debe ser al menos de 6 carácteres',
                  max: 'La longitud máxima del nombre del rol debe ser máximo 100 carácteres'
                }"
              />

              <FormulateInput
                name="description"
                v-model="formValues.description"
                label="Descripción del rol"
                placeholder="Descripción breve sobre el rol"
                :disabled="isLoading"
                validation="bail|required|min:15|max:255"
                :validation-messages="{
                  required: 'La descripción del rol es requerida',
                  min: 'La longitud mínima de la descripción debe ser al menos de 15 carácteres',
                  max: 'La longitud máxima de la descripción debe ser máximo de 255 carácteres'
                }"
              />

              <!-- Lista de permisos -->
              <check-authorization :requiresAuthorizations="['listar permisos']">
                <check-list
                  ref="checklist"
                  title="Permisos"
                  :fullList="permissions"
                  :currentList="currentRolePermissions"
                  @on-selecting="assignPermissions"
                />
              </check-authorization>

              <!-- Acciones, botones del formulario -->
              <div class="mt-4 d-flex flex-row-reverse">
                <div v-if="isEditing" class="w-100 d-flex justify-content-between">
                  <b-button
                    :disabled="isLoading"
                    @click="resetForm()"
                    type="reset"
                    variant="black"
                    size="sm"
                  >
                    Cancelar
                  </b-button>
                  <b-button
                    v-if="isFormValid"
                    type="submit"
                    :disabled="hasErrors || isLoading"
                    variant="warning"
                    size="sm"
                  >
                    {{ isLoading ? 'Actualizando...' : 'Actualizar' }}
                  </b-button>
                </div>
                <b-button
                  v-else
                  type="submit"
                  :disabled="hasErrors || isLoading"
                  variant="success"
                  size="sm"
                >
                  {{ isLoading ? 'Creando...' : 'Crear' }}
                </b-button>
              </div>
            </FormulateForm>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CheckList from '@/components/Shared/CheckList'

const formValues = () => ({
  name: '',
  description: '',
  permissions: []
})

export default {
  name: 'RoleCreate',

  components: {
    CheckList
  },

  created () {
    this.$store.dispatch('permissionModule/getPermissions')
  },

  data () {
    return {
      formValues: formValues(),

      isEditing: false,

      hasPermissions: false,

      errors: {}
    }
  },

  computed: {
    ...mapState('roleModule', ['currentRole']),

    ...mapState('permissionModule', ['permissions', 'currentRolePermissions']),

    isFormValid () {
      return [
        Boolean(this.hasPermissions),
        Boolean(this.formValues.name),
        Boolean(this.formValues.description)
      ].every(e => e)
    }
  },

  watch: {
    currentRole: {
      immediate: true,
      deep: true,
      handler (newCurrentRole) {
        this.isEditing = Boolean(newCurrentRole)

        if (newCurrentRole !== null) {
          this.formValues = newCurrentRole.role
          this.$store.commit(
            'permissionModule/setCurrentRolePermissions',
            newCurrentRole.permissions
          )
          return
        }

        this.$store.commit('permissionModule/setCurrentRolePermissions', [])
      }
    }
  },

  methods: {
    ...mapActions('UIModule', ['setNotification']),

    async submit () {
      this.prepareData()

      const getval = this.isEditing
        ? await this.updateRole(this.formValues)
        : await this.createRole(this.formValues)

      this.$notify(getval, 'Roles: Envío de formulario')

      if (!getval.error) return this.resetForm()

      this.errors = getval.data.errors ? getval.data.errors : []
    },

    async createRole (formValues) {
      return await this.$store.dispatch('roleModule/createRole', formValues)
    },

    async updateRole (formValues) {
      return await this.$store.dispatch('roleModule/updateRole', {
        id: formValues.id,
        payload: formValues
      })
    },

    prepareData () {
      if (this.formValues.permissions.length) return

      this.formValues.permissions = this.formValues.permissions.map(el => el.name)
    },

    assignPermissions (data) {
      this.formValues.permissions = data.map(el => el.name)

      this.hasPermissions = Boolean(this.formValues.permissions)
    },

    resetForm () {
      try {
        document.activeElement.blur()
        this.formValues = formValues()
        this.$store.commit('roleModule/setCurrentRole', null)
        this.$refs.roleForm.hideErrors()
        this.$refs.checklist.clearForm()
      } catch (_) {
      } finally {
        this.$store.dispatch('userModule/getAuthUser')
      }
    }
  }
}
</script>
