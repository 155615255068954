<template>
  <div>
    <label class="small font-weight-bolder">{{ title }}</label>

    <!-- Seleccionar todo -->
    <b-form-checkbox class="mb-2 user-select-none" v-model="selectAll" switch @change="checkAll">
      <strong>{{ selectAll ? 'Deseleccionar todo' : 'Seleccionar todo' }}</strong>
    </b-form-checkbox>

    <b-row>
      <!-- Lista de permisos completos o filtrados -->
      <b-col cols="6">
        <b-card
          no-body
          header="Todo"
          header-bg-variant="warning"
          header-text-variant="light"
          border-variant="warning"
          class="card-scrollable"
          style="height: 400px; overflow: auto;"
        >
          <b-form-group class="w-full m-0">
            <b-form-input v-model="input" type="search" placeholder="Filtrar" class="rounded-0" />
          </b-form-group>

          <b-list-group class="list__container rounded-0" flush>
            <b-list-group-item
              v-for="(item, index) in getList"
              :key="`${item}-${index}`"
              v-show="!isPermissionSelected(item)"
              @click="onSelectItem(item)"
              button
              variant="light"
              v-b-tooltip.hover.top="item.description"
            >
              {{ item.name }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>

      <!-- Lista de permisos seleccionados -->
      <b-col cols="6">
        <b-card
          no-body
          header="Seleccionados"
          header-bg-variant="success"
          header-text-variant="light"
          border-variant="success"
          class="card-scrollable"
          style="height: 400px; overflow: auto;"
        >
          <b-list-group class="list__container rounded-0" flush>
            <b-list-group-item
              v-for="(item, index) in localSelectedList"
              :key="`${item}-${index}`"
              variant="success"
              button
              @click="onDeselectItem(item)"
              v-b-tooltip.hover.top="item.description"
            >
              {{ item.name }}
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CheckList',

  props: {
    fullList: {
      type: Array
    },

    currentList: {
      type: Array
    },

    title: {
      type: String,
      required: false,
      default: 'Lista'
    },

    placeholderSearch: {
      type: String,
      required: false,
      default: 'Buscar'
    },

    initAllSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      selectAll: this.initAllSelected,

      localFullList: [],

      localSelectedList: [],

      input: null
    }
  },

  computed: {
    /**
     * Retorna una lista.
     *
     * Si hay palabras de búsqueda en el formulario,
     * retorna entonces la lista filtrada, si no,
     * retorna la lista orginal.
     *
     * Para la lista original, sólo se muestran los elementos
     * que hay en ella si esos elementos no están en la lista
     * seleccionada, los elementos no cambian, sólo se muestran
     * o se ocultan de la lista original.
     *
     * Los elementos sí se eliminan de la lista local.
     */
    getList () {
      if (this.input) return this.localFullList

      return this.fullList
    }
  },

  watch: {
    fullList: {
      deep: true,
      immediate: true,
      handler (list) {
        this.localFullList = _.clone(list)
      }
    },

    currentList: {
      deep: true,
      immediate: true,
      handler (list) {
        this.localSelectedList = list
      }
    },

    input: {
      deep: true,
      immediate: true,
      handler (search) {
        if (search === '') {
          this.localFullList = _.clone(this.fullList)
          return
        }

        this.localFullList = this.search(search, this.fullList)
      }
    },

    /**
     * Watch para emitir el resultado de la lista.
     */
    localSelectedList: {
      deep: true,
      immediate: true,
      handler (value) {
        this.emitSelectedList(value)
      }
    }
  },

  methods: {
    isPermissionSelected (item) {
      return this.localSelectedList.find(e => e.name === item.name)
    },

    /**
     * Mueve todos los elementos de una lista a otra.
     */
    checkAll () {
      if (!this.selectAll) {
        const list = this.localSelectedList
        list.map(item => this.onDeselectItem(item))
        return
      }

      const list = this.fullList
      list.map(item => this.onSelectItem(item))
    },

    search (word, list) {
      if (!list) return []

      const regex = new RegExp(word, 'i')

      const filtered = list.filter(item => regex.test(item.name))

      return filtered
    },

    onSelectItem (item) {
      this.localFullList = this.localFullList.filter(e => e.name !== item.name)

      this.localSelectedList.push(item)
    },

    onDeselectItem (item) {
      this.localSelectedList = this.localSelectedList.filter(e => e.name !== item.name)
    },

    emitSelectedList (list) {
      this.$emit('on-selecting', list)
    },

    clearForm () {
      this.input = null
      this.localFullList = []
      this.localSelectedList = []
      this.selectAll = false
    }
  }
}
</script>

<style lang="scss">
#list {
  max-height: 300px;
  overflow-y: scroll;
}

.list__container {
  max-height: 400px;
  overflow: auto;
}
</style>
