<template>
  <div>
    <check-authorization :requiresAuthorizations="['editar permisos']">
      <permission-form />
    </check-authorization>

    <check-authorization :requiresAuthorizations="['listar permisos']">
      <permission-list />
    </check-authorization>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import PermissionForm from '@/components/Permissions/PermissionForm'
import PermissionList from '@/components/Permissions/PermissionList'

export default {
  name: 'Permissions',

  components: {
    PermissionForm,
    PermissionList
  },

  computed: {
    ...mapGetters('permissionModule', ['isEditing'])
  }
}
</script>

<style lang="scss" scoped></style>
